$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
);
@each $size, $width in $border-widths {
  .border-#{$size} {
    border-width: $width !important;
  }
}
.projects img.table-avatar,
.projects .table-avatar img {
  height: 2.5rem;
}
.greenball {
  color: green;
  width: 10px;
}

.blueball {
  color: blue;
  width: 10px;
}

.redball {
  color: red;
  width: 10px;
}
