.wrap {
  padding-right: 126px;
}
.tableSummary {
  td:last-of-type {
    padding-right: 12px !important;
    padding-left: 12px !important;
    width: 126px;
  }
  th:last-of-type {
    padding-right: 12px !important;
    padding-left: 12px !important;
    width: 180px;
  }
}
