.wrap {
  :global {
    .DayPickerInput {
      width: 100%;
    }
    .DayPickerInput-Overlay {
      width: 550px;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: #f0f8ff !important;
      color: #4a90e2;
    }
    .DayPicker-Day {
      border-radius: 0 !important;
    }
    .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  }
  .dateSeparator {
    line-height: 38px;
  }
  .inputEnd {
    :global .DayPickerInput-Overlay {
      margin-left: calc(-100% + -43px);
    }
  }
}
